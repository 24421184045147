import React, {useState} from "react";
import styled from "styled-components";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { apiEndPoints } from "../../api/network/apiEndPoints";
import { useForm } from "react-hook-form";
import { postForm } from "../../api/redux/contact";
import Button from "../Button";
import {Loading} from "../Loading";

const MyComponent = ({ data }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {register,formState, handleSubmit, formState: {errors}, reset} = useForm({
    mode: "all",
  })

  //--- form submit
  const success = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      closeOnClick: true,
      progress: undefined,
    });

  const onSubmit = async (e) => {
    let api_services = apiEndPoints?.FORM_SUBMIT;

    var formData = new FormData();
    formData.append("name", e?.name);
    formData.append("email", e?.email);
    formData.append("phone", e?.phone);
    formData.append("message", e?.message);
    formData.append("form_id", "contact-form");

    if (e.email !== "" && e.name !== "" && e.phone !== "") {
      setLoading(true);
      document.body.style.overflow = 'hidden';
      await dispatch(postForm([api_services, formData]));
      setLoading(false);
      document.body.style.overflow = 'auto';
      success("Thank you for contacting with us!");
      reset();
    }
  };

  return (
    <StyledComponent className={"contact-form pt-200 pb-200"}>
      {loading && <Loading />}
      <Container>
        <Row>
          <Col className={'p-0'} lg={{ span: 5 }} md={12}>
            <div className={"contact-form__img"}>
              <img src={data?.images?.list?.[0]?.full_path} alt='A scenic view of a sunset'/>
            </div>
          </Col>
          <Col lg={{span: 6, offset: 1}} md={12}>


            <Form className={"form"} onSubmit={handleSubmit(onSubmit)}>

              <Col md={12}>
                <h5>Get in Touch!</h5>
              </Col>

              <input name={"spam_protector"} type="hidden" />

              <Col sm={12}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                      className={
                        formState?.errors?.name?.message
                            ? "has-error form-control-lg"
                            : "form-control-lg"
                      }
                      {...register("name", {
                        required: "Please enter your full name",
                        pattern: {
                          value: /([A-Za-z])\w+/,
                          message: "Please enter your full name",
                        },
                      })}
                      type="text"
                      placeholder="Name*"
                  />
                  <p className={'form-error'}>{errors.name?.message}</p>

                </Form.Group>
              </Col>

              <Col sm={12}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                      className={
                        formState?.errors?.email?.message
                            ? "has-error form-control-lg"
                            : "form-control-lg"
                      }
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Please enter your email",
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Please enter a valid email address",
                        },
                      })}
                      type="email"
                      placeholder="Email*"
                  />
                  <p className={'form-error'}>{errors.email?.message}</p>

                </Form.Group>
              </Col>

              <Col sm={12}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                      className={
                        formState?.errors?.phone?.message
                            ? "has-error form-control-lg"
                            : "form-control-lg"
                      }
                      {...register("phone", {
                        required: {
                          value: true,
                          message: "Please enter your phone number",
                        },
                        pattern: {
                          value: /^01[0-9]{9}$/,
                          message: "Please enter a valid 11 digit phone number",
                        },
                      })}
                      type="number"
                      placeholder="Phone Number*"
                  />
                  <p className={'form-error'}>{errors.phone?.message}</p>

                </Form.Group>
              </Col>

              <Col sm={12}>
                {/*<textarea name="" id="" placeholder={'Message*'}/>*/}
                <textarea
                    className={
                      formState?.errors?.message?.message
                          ? "has-error form-control-lg"
                          : "form-control-lg"
                    }
                    {...register("message", {
                      required: {
                        message: "Please enter your message",
                      },
                    })}
                    placeholder="Message (Optional)"
                ></textarea>

              </Col>

              <Col sm={12}>
                <div onClick={handleSubmit(onSubmit)} className={'submit-btn'}>
                  <Button
                    hoverBackground={"#CA0034"}
                    hoverBorderColor={"#CA0034"}
                    background={"transparent"}
                    border={"1px solid #F8F8F9"}
                    color={"#F8F8F9"}
                    text={"Submit Message"}
                  />
                </div>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  background: #2b3944;
  border-bottom: 1px solid #f8f8f9;
position: relative;
  .form {
    padding: unset;
  }

  .contact-form__img img {
    width: 100%;
  }
  
  .col-lg-6 {
    padding-right: 0px;
  }

  .col-lg-5 {
    padding-left: 0px;
  }

  h5 {
    color: #f8f8f9 !important;
    margin-bottom: 60px !important;
  }

  .form textarea {
    padding: 0px;
    border-bottom: 1px solid rgba(248, 248, 249, 0.2)!important;
  }

  //.contact-form {
  //  &__img {
  //    position: relative;
  //    padding-top: calc(458 / 530 * 100%);
  //    height: 100%;
  //    width: 100%;
  //  }
  //}

  input:-webkit-autofill {
    color: white !important;
  }

  input:focus {
    border-color: #f8f8f9;
    /* You can also change other styles like background-color, box-shadow, etc. */
  }

  @media (min-width: 768px) and (max-width: 991px) {
    h3 {
      margin-left: 15px;
    }

    .col-md-12 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media (max-width: 767px) {
    .form {
      padding-top: 60px;
    }

    .row {
      margin-left: -30px;
      margin-right: -30px;
    }
  }
  @media (min-width: 340px) and (max-width: 400px) {
    .dc-btn {
      width: 65% !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1499px) {
    .dc-btn {
      width: 60% !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .dc-btn {
      width: 80% !important;
    }
  }
  
  .submit-btn{
    width: max-content;
  }
`;
export default MyComponent;
