import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({data, id}) => {
    const [offset, setoffset] = useState(90)

    const corporateOffice = data?.posts?.list.find((f) => f?.data?.slug === "corporate-office")
    const hotline = data?.posts?.list.find((f) => f?.data?.slug === "hotline")
    const telephone = data?.posts?.list.find((f) => f?.data?.slug === "telephone")
    const email = data?.posts?.list.find((f) => f?.data?.slug === "email")
    const map = data?.posts?.list.find((f) => f?.data?.slug === "map")


    useEffect(() => {
        setoffset(document.querySelector(' .container').offsetLeft)
        window.addEventListener('load', function () {
            setoffset(document.querySelector(' .container').offsetLeft)
            // document.querySelector('#controlled-tab-example-tab-tab80').click();
        })
        window.addEventListener('resize', function () {
            setoffset(document.querySelector(' .container').offsetLeft)

        })
    }, [])

    return (

        <StyledComponent getoffset={offset} id={`${id ? id : 'AddressTab'}`} offset={offset}
                         className={`addresstab pt-100`}>
            <Container fluid>
                <Row>
                    <div className="tab_container">
                        <Col md={12}>
                            <div className="custom_pad row m-0 pb-100">
                                <Col lg={3}>
                                    <div className="single-tab">
                                        <p>Corporate Office</p>
                                        <p>
                                            <a
                                                target={"_blank"}
                                                rel="noopener noreferrer"
                                                href={`${corporateOffice?.data?.subtitle}`}>
                                                {reactHtmlParser(corporateOffice?.data?.description)}
                                            </a>
                                        </p>

                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="single-tab">
                                        <p>Hotline</p>

                                        {reactHtmlParser(hotline?.data?.description)}
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="single-tab">
                                        <p>Telephone</p>
                                        {reactHtmlParser(telephone?.data?.description)}
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="single-tab">
                                        <p>Email</p>
                                        {reactHtmlParser(email?.data?.description)}
                                    </div>
                                </Col>
                            </div>
                            <Row>
                                <div className="clearfix"></div>
                                <Col lg={12} className={'google_map'}>
                                    <div className="from-left">
                                        <a
                                            target={'_blank'}
                                            rel="noopener noreferrer"
                                            href={`${map?.images?.[0]?.short_title}`}>
                                            <div className={'map_img'}>
                                                <Img src={map?.images?.[0]?.full_path}/>
                                            </div>
                                        </a>

                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    padding-top: 80px;

    .single-tab p:nth-of-type(1) {
        color: #9CABB9;
        padding-bottom: 10px;
    }

    .single-tab p:nth-of-type(2) {
        color: #2B3944;
    }

    .from-left {
        padding-left: ${props => props.getoffset ? props.getoffset - 20 + 'px' : ''};
    }


    @media (max-width: 991px) {
        p, h3 {
            margin-bottom: 30px;
        }

        .col-md-2 {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0px;

            }

        }
    }
    @media (max-width: 992px) {
        overflow: hidden;
    }
    @media (max-width: 767px) {
        //.google_map{
        //  padding: 0;
        //}
    }

    .map_img {
        position: relative;
        padding-top: calc(560 / 1268 * 100%);
    }

    .formobile {
        display: none;
        @media (max-width: 767px) {
            display: block;
            margin: 0;
            padding: 0;
        }
    }

    .container-fluid {
        position: relative;
        z-index: 2;
        margin: 0;
        width: 100%;
        max-width: 100%;
        padding-right: 0;


        .google_map {
            padding-right: 0;
            position: relative;
        }

        .google_map:before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            height: 50%;
            background: #2B3944;
            bottom: 0;
        }

        @media (max-width: 767px) {
            padding: 0 15px !important;
        }

    }

    .custom_pad {
        padding-right: ${props => props.getoffset ? props.getoffset - 30 + 'px' : ''};
        padding-left: ${props => props.getoffset ? props.getoffset - 30 + 'px' : ''};
    }

    .tab_container {
        width: 100%;

        .nav-tabs {
            border: none;
            padding-right: ${props => props.getoffset ? props.getoffset + 15 + 'px' : ''};

            @media (max-width: 767px) {
                display: none;
            }

            .nav-item {
                border: none;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                .nav-link {
                    border: 1px solid #F9F9F9;
                    height: 45px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                    background: #4F616B;
                    border-radius: 25px;
                    padding: 0 30px;
                    line-height: 45px;
                    position: relative;
                    transition: 0.8s all cubic-bezier(0.4, 0, 0, 1);

                    &:after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background: white;
                        content: "";
                        border-radius: 25px;
                        transition: 0.8s all cubic-bezier(0.4, 0, 0, 1);
                        transform: scale(0);
                        z-index: -1;
                    }

                    &:hover {
                        color: #4F616B;

                        &:after {
                            transform: scale(1);

                        }
                    }

                    &.active {
                        color: #4F616B;
                        background: transparent;

                        &:after {
                            transform: scale(1);

                        }
                    }
                }

            }
        }

        .google_map {
            //margin-top: 40px;
        }

        .tab-pane {
            padding-top: 80px;
            @media (max-width: 767px) {
                padding-top: 40px;
                .col-md-3, .col-md-4 {
                    margin-bottom: 20px;
                }
            }

            p, a {
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                display: block;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            h3 {
                color: #F9F9F9;
                font-size: 28px;
                font-weight: 500;
                line-height: 32px;
            }
        }
    }

    .css-13cymwt-control {
        border-radius: 0;

        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }

    /* small mobile :320px. */
    @media (max-width: 767px) {
        .single-tab p:nth-of-type(1) {
            padding-bottom: unset;
        }

        .from-left {
            padding-left: unset;
        }

        .col-md-12 {
            padding: 0px;
        }
    }
`;

export default MyComponent;
